/*******custom-component css*******/

.inner-page-main {
    min-height: calc(100svh - 4.5em - 9em);
    height: auto;
}










.min-card-box {
    display: none;
    flex-direction: row;
    width: 100%;
    height: 6em;
    align-items: center;
    justify-content: space-between;
    transform: translateY(-2em);
}


.min-car-card-box .car-card-box {
    display: none ;
 }

 .min-car-card-box .min-card-box {
    display: flex;
 }



.min-car-card-box  .min-car-card .car {
    max-width: 90em;
}

.min-car-card-box .car {
    max-width: 90em;
}



img.car-image-min {
    max-height: 6em;
}

.min-car-card-box .car-card.active {
    width: 100% !important;
}

.min-car-card-box .car-carousel-inner {
    width: 100% !important;
}

body#amg-cp-main:has(#prof-mobile .container .overlay.active) {
    overflow: hidden;
}


.main-sec {
    width: 100%;
}

.profile-about-social {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}


main#main-content:has(.legal-page-container) ~ .language-picker,
main#main-content:has(#legal-screen) ~ .language-picker {  background: #000c content-box; padding: 4.5em 0 0; }

body:has(#language-selection-pop) { overflow: clip;}

#amg-cp-main ng9-odometer,
#amg-cp-main .odometer,
#amg-cp-main .odometer .odometer-inside,
#amg-cp-main .odometer span { font-family: "DaimlerCAC-Regular", serif !important; }

#amg-cp-main .odometer.odometer-auto-theme, #amg-cp-main .odometer.odometer-theme-default {
    font-family: "DaimlerCAC-Regular", serif !important;
    line-height: 1.1em;
}

#amg-cp-main .npm-digit,
#amg-cp-main .odometer,
#amg-cp-main .odometer-digit-inner,
#amg-cp-main .odometer-digit-spacer,
#amg-cp-main .odometer-formatting-mark,
#amg-cp-main .odometer-inside,
#amg-cp-main .odometer-ribbon,
#amg-cp-main .odometer-ribbon-inner,
#amg-cp-main .odometer-value {
    font-family: "DaimlerCAC-Regular", serif !important;
    line-height: 1.1em;
}

@media screen and (max-device-width: 580px), (max-width: 768px){


    .min-card-box {
        width: 100% !important;
        padding: 0 1.5em;
    }
    .min-car-card-box .car-cards-section .user-car .car-carousel {
        padding: 0 0;
    }


}

@media only screen and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {



}

.min-car-card-box .car-cards-section .user-car .car-card.active .car-image {
    position: absolute;
    height: calc(100% - 2em);
    width: auto;
    aspect-ratio: 16/9;
    right: 0;
    top: -1em;
    bottom: 2em;
    left: auto;
    margin: auto;
    transition: all 2s linear;
}
.legal-page-container {
    /* height: calc(100svh - 4.5em); */
    background: #f2f2f2;
    padding: 1.5em 1.5em 1em 0.5em;
    overflow-y: auto;
    overflow-x: auto;
}

@media screen and (max-width: 767px) {

.rc-shr-title { padding: 1.5em 0 !important;}
.rc-shr-title  h1 { font-size: 1.75em; }

}



