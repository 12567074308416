 
body { font-size: 1em; font-weight: 500; font-style: normal; font-display: auto; -webkit-font-smoothing: antialiased; text-rendering: optimizeLegibility; }


h1,h2, h3 {  font-weight: 400 !important; }
h4,h5,h6 { font-weight: 800 !important; }
h1 { font-size: 2.5em;}
h2 { font-size: 2em;}
h3 { font-size: 1.75em;}
h4 { font-size: 1.5em;}
h5 { font-size: 1.25em;}
h6 { font-size: 1em; }


@font-face {
    font-family: "DaimlerCAPro-Regular";
    src: url(../../fonts/DaimlerCAPro-Regular.otf);
}

@font-face {
    font-family: "DaimlerCA-Regular";
    src: url(../../fonts/DaimlerCA-Regular.eot);
    src: url(../../fonts/DaimlerCA-Regular.ttf), url(../../fonts/DaimlerCA-Regular.otf),
        url(../../fonts/DaimlerCA-Regular.woff), url(../../fonts/DaimlerCA-Regular.svg);
}

@font-face {
    font-family: "DaimlerCAC-Regular";
    src: url(../../fonts/DaimlerCAC-Regular.eot);
    src: url(../../fonts/DaimlerCAC-Regular.ttf), url(../../fonts/DaimlerCAC-Regular.otf),
        url(../../fonts/DaimlerCAC-Regular.woff), url(../../fonts/DaimlerCAC-Regular.svg);
}

@font-face {
    font-family: "DaimlerCSPro-Regular";
    src: url(../../fonts/DaimlerCSPro-Regular.otf);
}

@font-face {
    font-family: "DaimlerCS-Bold";
    src: url(../../fonts/DaimlerCS-Bold.eot);
    src: url(../../fonts/DaimlerCS-Bold.ttf), url(../../fonts/DaimlerCS-Bold.otf),
        url(../../fonts/DaimlerCS-Bold.woff), url(../../fonts/DaimlerCS-Bold.svg);
}

@font-face {
    font-family: "DaimlerCS-BoldItalic";
    src: url(../../fonts/DaimlerCS-BoldItalic.ttf);
}

@font-face {
    font-family: "DaimlerCS-Regular";
    src: url(../../fonts/DaimlerCS-Regular.eot);
    src: url(../../fonts/DaimlerCS-Regular.otf), url(../../fonts/DaimlerCS-Regular.ttf),
        url(../../fonts/DaimlerCS-Regular.woff), url(../../fonts/DaimlerCS-Regular.woff2);
}

@font-face {
    font-family: "DaimlerCS-Demi";
    src: url(../../fonts/DaimlerCS-Demi.eot);
    src: url(../../fonts/DaimlerCS-Demi.otf), url(../../fonts/DaimlerCS-Demi.ttf),
        url(../../fonts/DaimlerCS-Demi.woff);
}

@font-face {
    font-family: "DaimlerCS-light";
    src: url(../../fonts/DaimlerCS-Light.ttf);
    src: url(../../fonts/DaimlerCS-Light.woff2);
}

@font-face {
    font-family: "DaimlerCS-lightItalic";
    src: url(../../fonts/DaimlerCS-LightItalic.ttf);
}

@font-face {
    font-family: "DaimlerCACPro-Regular";
    src: url(../../fonts/DaimlerCACPro-Regular.otf);
}


@font-face {
    font-family: "DaimlerCAPro-Regular";
    src: url(../../fonts/DaimlerCAPro-Regular.otf);
}

@font-face {
    font-family: "DaimlerCA-Regular";
    src: url(../../fonts/DaimlerCA-Regular.eot);
    src: url(../../fonts/DaimlerCA-Regular.ttf), url(../../fonts/DaimlerCA-Regular.otf),
        url(../../fonts/DaimlerCA-Regular.woff), url(../../fonts/DaimlerCA-Regular.svg);
}

@font-face {
    font-family: "DaimlerCAC-Regular";
    src: url(../../fonts/DaimlerCAC-Regular.eot);
    src: url(../../fonts/DaimlerCAC-Regular.ttf), url(../../fonts/DaimlerCAC-Regular.otf),
        url(../../fonts/DaimlerCAC-Regular.woff), url(../../fonts/DaimlerCAC-Regular.svg);
}

@font-face {
    font-family: "DaimlerCSPro-Regular";
    src: url(../../fonts/DaimlerCSPro-Regular.otf);
}

@font-face {
    font-family: "DaimlerCS-Bold";
    src: url(../../fonts/DaimlerCS-Bold.eot);
    src: url(../../fonts/DaimlerCS-Bold.ttf), url(../../fonts/DaimlerCS-Bold.otf),
        url(../../fonts/DaimlerCS-Bold.woff), url(../../fonts/DaimlerCS-Bold.svg);
}

@font-face {
    font-family: "DaimlerCS-BoldItalic";
    src: url(../../fonts/DaimlerCS-BoldItalic.ttf);
}

@font-face {
    font-family: "DaimlerCS-Regular";
    src: url(../../fonts/DaimlerCS-Regular.eot);
    src: url(../../fonts/DaimlerCS-Regular.otf), url(../../fonts/DaimlerCS-Regular.ttf),
         url(../../fonts/DaimlerCS-Regular.woff), url(../../fonts/DaimlerCS-Regular.woff2);

}

@font-face {
    font-family: "DaimlerCS-Demi";
    src: url(../../fonts/DaimlerCS-Demi.eot);
    src: url(../../fonts/DaimlerCS-Demi.otf), url(../../fonts/DaimlerCS-Demi.ttf),
        url(../../fonts/DaimlerCS-Demi.woff);
}

@font-face {
    font-family: "DaimlerCS-light";
    src: url(../../fonts/DaimlerCS-Light.ttf);
    src: url(../../fonts/DaimlerCS-Light.woff2);
}

@font-face {
    font-family: "DaimlerCS-lightItalic";
    src: url(../../fonts/DaimlerCS-LightItalic.ttf);
}

@font-face {
    font-family: "DaimlerCACPro-Regular";
    src: url(../../fonts/DaimlerCACPro-Regular.otf);
}

@font-face {
    font-family: "MBcorp-web-Regular";
    src: url(../../fonts/MBCorpoSText-Regular-Web.woff2);
    font-weight: 400;
}

@font-face {
    font-family: "MBcorp-web-Bold";
    src: url(../../fonts/MBCorpoSText-Bold-Web.woff);
}

@font-face {
    font-family: "MBcorp-web-light";
    src: url(../../fonts/MBCorpoSText-Light-Web.woff);
}


h1,
h2,
h3,
h1 > span,
h2 > span,
h3 > span {
  font-family: "DaimlerCAC-Regular", serif !important;
}

p,
div,
label,
span,
ul,
li,
img {
  margin: 0;
  padding: 0;
  border: 0 none;
  font-family: "MBcorp-web-Regular", sans-serif !important;
}

h4,
h5,
h6 {
  font-family: "MBcorp-web-Bold", sans-serif !important;
}
