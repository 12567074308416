.icon.big { width: 3em; height: 3em; display: block; }

.icon_rank { background: url(../../images/icon-rank.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_race { background: url(../../images/icon-race.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_failed { background: url(../../images/icon_cross.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_success { background: url(../../images/icon_tick.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_info { background: url(../../images/icon_info.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon.icon_up_right { background: url(../../images/navigation-arrow-up-right.png) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon.icon_arrow_down { background: url(../../images/dropdown-icon.webp) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon.icon_close { background: url(../../images/close-button-icon.png) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon.icon_back { background: url(../../images/chevron-left.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}


