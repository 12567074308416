


#amg-cp-main > * ::-webkit-scrollbar { width:0.5em; height: 0.5em; }
#amg-cp-main > * ::-webkit-scrollbar-track{ background: transparent;  border-radius:0.5em; border: calc(0.25em - 0.5px) solid transparent;  background-clip: content-box; } 
#amg-cp-main > * ::-webkit-scrollbar-thumb { width: auto; height: auto; cursor: pointer; background: var(--clrscrollthumb-gradient); border-radius: 0.5em; background-clip: content-box; border: 2px solid transparent; }
#amg-cp-main > * ::-webkit-scrollbar-thumb:hover { background: var(--clrscrollthumb-solid); background-clip: content-box; }

.lt-row-w-g, .lt-row { flex-direction: column; }
[class^="clm-"], [class*=" clm-"] { width: 100% !important;  }
@media screen and (max-device-width: 580px) and (max-width: 359px){
    body#amg-cp-main, body {
        font-size: min(3.5vw, 1.75vh, 0.625em)!important;
    }
}