/*******color css*******/

html { color-scheme: dark; }
body#amg-cp-main { background: var(--wb-black); color-scheme: dark; }

a { color: var(--clrprimary);}
a:hover { color: var(--clrsecondary);}

.title-bar { color: #fff; }
.sub-title-color {color: #fff; opacity: 0.5; }

.info-box.default { background: #0076AB;}
.info-box.success { background: #25B24A;}
.info-box.failed { background: #EF0707;}
.info-box.neutral { background: #707070;}

