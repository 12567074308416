/*******Layout css*******/

html { width: 100%; height: 100%; }
body { margin: 0; padding: 0; direction: ltr; text-align: left; word-wrap: break-word; }

body { width: 100%; height: 100%; overflow: auto; position: relative;  }

*, *:before, *:after{ box-sizing:border-box; }

html { text-size-adjust:100%; }
body { margin:0;  padding:0; }

body > div { position: relative;min-height: calc(100vh - 4.5em);overflow-x: clip; box-sizing: border-box;  }

/************Reset Css*********************/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: inherit;
	vertical-align: baseline;
  /*user-select: none;*/
}

/******************Global***********************/

img { margin: 0; max-width: 100%; border: 0 solid transparent; }
h1, h2, h3, h4 , h5 { margin: 0 0 0.0625em;padding: 0 0 0; }

* { box-sizing: border-box;  }
/* * { -webkit-transition: all .2s 1s, visibility 0s 0s; -moz-transition: all .2s 1s, visibility 0s 0s; 	transition: all .2s 0s, visibility 0s 0s; box-sizing: border-box; } */
* :focus { outline: none; }

/**************Skeleton*********************/

header {  width: 100%;  position: fixed; top:0; left: 0; right: 0; z-index: 960; }
header::after {  width: 100%;  content: ""; position: absolute; top:0; backdrop-filter: blur(2em); left: 0; right: 0; animation: revealcont 3s ease-in-out 1; z-index: -1001; }
header.no-animation::after {  animation: none; display: none; }

@keyframes revealcont { 0% { height: 250vh; background: #000000; }
33% { height: 150vh; background: #000000f8; }
66% { height: 100vh; background: #000000ee; }
100% { height: 0; background: #00000080;}
}

main { width: 100%; min-height: calc(100svh - 4.5em); overflow-x: clip; padding: 4.5em 0 0; position: relative; z-index: 2; }
main > * { width: 100%; min-height: 100%; }

/***************************/

#amg-cp-main > * ::-webkit-scrollbar { width:0.75em; height: 0.75em; }
#amg-cp-main > * ::-webkit-scrollbar-track{ background: transparent;  border-radius:0.5em; border: calc((0.75em/2) - 1px) solid transparent;  background-clip: content-box; }
#amg-cp-main > * ::-webkit-scrollbar-thumb { width: auto; height: auto; cursor: pointer; background: var(--clrscrollthumb-gradient); border-radius: 0.5em; background-clip: content-box; border: 2px solid transparent; }
#amg-cp-main > * ::-webkit-scrollbar-thumb:hover { background: var(--clrscrollthumb-solid); background-clip: content-box; }

#amg-cp-main:has(.anonymize-popup) #parent {
  display: none;
}

#amg-cp-main:has(#landing-page-main) {
  overflow: hidden;
  height: 100svh;
}
/*********grid-sys**************/

.g-rw {display: flex;width: 100%;flex-wrap: wrap;flex-direction: row;gap: 1em;align-content: flex-start;}
[class^="clm-"], [class*=" clm-"] { display: flex; }

.amg-row  { display: flex;flex-direction: row;gap: 1em;flex-wrap: wrap;justify-content: space-between;align-content: flex-start;align-items: flex-start;padding: 0 0 1em;width: 100%;}

.amg-row.no-gutter { gap: 0; }
.amg-row-w-g.h-streched-flx, .amg-row-no-g.h-streched-flx { align-items: stretch; }

.lt-row {display: flex;flex-direction: row;gap: 0; flex-wrap: wrap;justify-content: flex-start;align-content: flex-start;align-items: flex-start;padding: 0 0 1em; width: 100%; }
.lt-row-w-g  {display: flex;flex-direction: row;gap: 1em;flex-wrap: wrap;justify-content: space-between;align-content: flex-start;align-items: flex-start;padding: 0 0 1em;width: 100%;}

.lt-row.right {align-content: center;align-items: flex-end;display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: flex-end;}
.lt-row.space-between {align-content: center;align-items: flex-end;display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: space-between;}

.lt-row .clm-auto .lt-row { justify-content: flex-start; }

.ft-row { display: flex; flex-direction: row; flex-wrap: wrap; align-content: center; justify-content: space-between; align-items: center; }

.clm-auto { width: auto; }
.clm-auto.strech { width: auto; flex: 1 1 auto; }

/*******************************************/



.clm-1 { width: 8.33%; }
.clm-2 { width: 16.66%; }
.clm-3 { width: 25%; }
.clm-4 { width: 33.33%; }
.clm-5 { width: 41.66%; }
.clm-6 { width: 50%;}
.clm-7 { width: 58.33%; }
.clm-8 { width: 66.66%; }
.clm-9 { width: 75%; }
.clm-10 { width: 83.33%; }
.clm-11 { width: 91.66%; }
.clm-12 { width: 100%; }
.clm-auto { width: auto; }

.lt-row-w-g > .clm-1 { width: calc(8.33% - 1em); }
.lt-row-w-g > .clm-2 { width: calc(16.66% - 1em); }
.lt-row-w-g > .clm-3 { width: calc(25% - 1em);}
.lt-row-w-g > .clm-4 { width: calc(33.33% - 1em); }
.lt-row-w-g > .clm-5 { width: calc(41.66% - 1em); }
.lt-row-w-g > .clm-6 { width: calc(50% - 1em);}
.lt-row-w-g > .clm-7 { width: calc(58.33% - 1em); }
.lt-row-w-g > .clm-8 { width: calc(66.66% - 1em); }
.lt-row-w-g > .clm-9 { width: calc(75% - 1em); }
.lt-row-w-g > .clm-10 { width: calc(83.33% - 1em); }
.lt-row-w-g > .clm-11 { width: calc(91.66% - 1em); }
.lt-row-w-g > .clm-12 { width: 100%; }



/********************************************/

.pop-screen { width: 100%; min-height: 100%; left: 0; right: 0;  top: 0; bottom: 0; z-index: 1001; display: flex; flex-direction: column; flex-wrap: nowrap; align-content: center; align-items: center; justify-content: center;} */
.pop-ups { background: var(--bgpopup); min-width: 25em; padding: 3em; }
 .overlay { position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0; background: #00000080; z-index: -1; backdrop-filter: blur(0.25em);} */

.pop-screen.trans .pop-ups { background: transparent;}

/******************************************/

@media screen and (min-width: 1281px) {

  .desk-only { display: block; }
  .mob-only { display: none; }
  [class^="clm-tab-"], [class*=" clm-tab-"] { width: inherit; }
  .clm-1 { width: 8.33%; }
  .clm-2 { width: 16.66%; }
  .clm-3 { width: 25%; }
  .clm-4 { width: 33.33%; }
  .clm-5 { width: 41.66%; }
  .clm-6 { width: 50%;}
  .clm-7 { width: 58.33%; }
  .clm-8 { width: 66.66%; }
  .clm-9 { width: 75%; }
  .clm-10 { width: 83.33%; }
  .clm-11 { width: 91.66%; }
  .clm-12 { width: 100%; }
  .clm-auto { width: auto; }

  .lt-row-w-g > .clm-1 { width: calc(8.33% - 1em); }
  .lt-row-w-g > .clm-2 { width: calc(16.66% - 1em); }
  .lt-row-w-g > .clm-3 { width: calc(25% - 1em);}
  .lt-row-w-g > .clm-4 { width: calc(33.33% - 1em); }
  .lt-row-w-g > .clm-5 { width: calc(41.66% - 1em); }
  .lt-row-w-g > .clm-6 { width: calc(50% - 1em);}
  .lt-row-w-g > .clm-7 { width: calc(58.33% - 1em); }
  .lt-row-w-g > .clm-8 { width: calc(66.66% - 1em); }
  .lt-row-w-g > .clm-9 { width: calc(75% - 1em); }
  .lt-row-w-g > .clm-10 { width: calc(83.33% - 1em); }
  .lt-row-w-g > .clm-11 { width: calc(91.66% - 1em); }
  .lt-row-w-g > .clm-12 { width: 100%; }



}

@media only screen and (max-width: 1280px) {
 .clm-1 { width: 8.33%; }
	.clm-2 { width: 16.66%; }
	.clm-3 { width: 25%; }
	.clm-4 { width: 33.33%; }
	.clm-5 { width: 41.66%; }
	.clm-6 { width: 50%;}
	.clm-7 { width: 58.33%; }
	.clm-8 { width: 66.66%; }
	.clm-9 { width: 75%; }
	.clm-10 { width: 83.33%; }
	.clm-11 { width: 91.66%; }
	.clm-12 { width: 100%; }
	.clm-auto { width: auto; }

	.lt-row-w-g > .clm-1 { width: calc(8.33% - 1em); }
	.lt-row-w-g > .clm-2 { width: calc(16.66% - 1em); }
	.lt-row-w-g > .clm-3 { width: calc(25% - 1em);}
	.lt-row-w-g > .clm-4 { width: calc(33.33% - 1em); }
	.lt-row-w-g > .clm-5 { width: calc(41.66% - 1em); }
	.lt-row-w-g > .clm-6 { width: calc(50% - 1em);}
	.lt-row-w-g > .clm-7 { width: calc(58.33% - 1em); }
	.lt-row-w-g > .clm-8 { width: calc(66.66% - 1em); }
	.lt-row-w-g > .clm-9 { width: calc(75% - 1em); }
	.lt-row-w-g > .clm-10 { width: calc(83.33% - 1em); }
	.lt-row-w-g > .clm-11 { width: calc(91.66% - 1em); }
	.lt-row-w-g > .clm-12 { width: calc(100% - 1em); }

}



@media only screen and (max-width:375px){
  body{  font-size: 0.675em;   height:100%; }
}

@media only screen and (max-width:320px){
  body{  font-size: 0.675em;   height:100%; }
}

@media only screen and (max-width:375px){
  body{  font-size: 0.675em;   height:100%; }
}

@media only screen and (max-width:320px){
  body{  font-size: 0.675em;   height:100%; }
}

@media only screen and (max-width:800px){
  html{  height:100%; }
  body{  font-size: 0.75em;   height:100%; }
  main { min-height: calc(100svh - 4.5em - 9em); }
}

@media only screen and (max-width:580px){
  body{  font-size: 0.75em; height:100%; }
  #amg-cp-main .leaflet-touch .leaflet-bar a {  font-size: 1.5em; }

}


@-moz-document url-prefix() {

	#amg-cp-main { scroll-behavior: smooth;  scrollbar-width: thin; scrollbar-color: #64646450 transparent;}

}
